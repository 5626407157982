import { AlphabetType, alphabetTypeToAcceptedLetters, findInvalidChars, hasInvalidChar } from "../../AlphabetType";

import { BaseGame } from "../BaseGame";
import { Category } from "./Category";

export interface WordFinderGame extends BaseGame {
    categories: Category[];
}

export const WordFinderConstants = {
    minGridSize: 5,
    maxGridSize: 10, 
    maxCategoryCount: 5, 
    hintImgSize: 140
}



export const supportedAlphabets = [
    AlphabetType.English, 
    AlphabetType.Danish, 
    AlphabetType.Norwegian, 
    AlphabetType.Dutch,
    AlphabetType.Malay,
    AlphabetType.German,
    AlphabetType.Spanish,
    AlphabetType.Swedish, 
    AlphabetType.Hebrew, 
    AlphabetType.Italian
]; 

const optionalHebrewChars = ['"', '-', '\'', 'ץ', 'ך', 'ף', 'ן', 'ם']; 

export const getAlphabetChars = (alphabet: AlphabetType, removeWhiteSpace: boolean) => {
    let chars = alphabetTypeToAcceptedLetters[alphabet]; 
    if (removeWhiteSpace){
        chars = chars.filter(x => x !== ' ');
    }
    if (alphabet === AlphabetType.Hebrew){
        chars = chars.filter(x => !optionalHebrewChars.includes(x));
    }
    return chars; 
}

export const hasInvalid = (word: string, alphabet: AlphabetType) => {
    return hasInvalidChar(word, getAlphabetChars(alphabet, false)); // do not filter out white space, it is allowed in words
}

export const findInvalid = (word: string, alphabet: AlphabetType) => {
    return findInvalidChars(word, getAlphabetChars(alphabet, false));
}

// temporary WordFinder to update old games that do not store Alphabet Type (but only chars) in their models
export const getAlphabetType = (x: string): AlphabetType | undefined => {
    const res: AlphabetType[] = []; 
    for (const e of Object.entries(alphabetTypeToAcceptedLetters)){
        if(alphabetsAreEqual(x.split(""), e[1])){
            res.push(e[0] as AlphabetType);
        }
    }
    return res.length ? res[0] : undefined;
}

const alphabetsAreEqual = (alphabet1: string[], alphabet2: string[]): boolean => {
    const xChars = alphabet1.filter(x => x.trim().length > 0); 
    const yChars = alphabet2.filter(x => x.trim().length > 0); 
    if (xChars.length !== yChars.length) return false; 
    let res = true; 
    yChars.forEach(x => {if(!xChars.includes(x)) res = false;}); 
    xChars.forEach(x => {if(!yChars.includes(x)) res = false;}); 
    return res; 
}; 

export const updateCategoryModel = (category: Category) => ({...category, alphabet: getAlphabetType(category.availableChars) || category.alphabet}); 
export const updateAllCategoryModelsInGame = (game: WordFinderGame) => ({...game, categories: game.categories.map(updateCategoryModel)}); 