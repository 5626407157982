import React, {createContext, Dispatch, lazy, ReactNode, SetStateAction, Suspense, useCallback, useState,} from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import Login from "../Login";
import Logout from "../Logout";
import ResetPassword from "../ResetPassword";
import SecureRoute from "../SecureRoute";
import Discover from "../Discover/Discover";
import NotFound from "../NotFound";
import Layout from "../Layout";
import NavMenu from "../Navigation/NavMenu";
import GameAddToFloor from "../WorkShop/GameAddToFloor";
import AddPlaylistToFloor from "../forms/FloorMenu/AddPlaylistToFloor";
import AddGameToPlaylist from "../forms/AddGameToPlaylist";
import PopContainer from "../PopSplash/PopContainer";
import AccountEditForm from "../forms/profile/AccountEditForm";
import AddPlaylistBulk from '../forms/AddPlaylistBulk';
import { AccountType } from '../../model/AccountType';
import moment from 'moment';
import { BaseGame } from '../../model/Game/BaseGame';
import { PlayListType } from '../../model/PlayListType';
import PlaylistAddPlaylist from '../PlayList/PlaylistAddPlaylist';
import { FloorContent } from '../../model/Response/FloorContent';
import { Organization as OrgType } from '../../model/Organization';
import { EventEffects } from '../../events/PlaylistEvents';
import { playlistUpdatedSetPlaylist } from '../PlayList/Explorer/Helpers';
import { isRtlActive } from '../../services/TextHelper';
import { FetchError } from '../../services/FetchHelper';
import { trimChar, updateArrayItemsById } from '../../services/CustomFunctions';
import { DictGetValues } from '../../services/JsDict';
import { Routes } from 'react-router-dom';
import { Loading } from '../Loading';
import { SimpleGameContexts } from '../../api/BaseGameContext';
import OrganizationCharts from '../Organization/OrganizationCharts';
import CreateStaticGame from '../WorkShop/CreateStaticGame';
import { getToken } from '../../services/LocalStorageService';
import AdIndex from '../Advertisement/AdIndex';
import TrackingOptions from '../TrackingOptions';
import CurtainsContainer, { Curtain } from '../PopSplash/CurtainsContainer';
import WordFinder from '../WorkShop/WordFinder/WordFinder';

const Tutorials = lazy(() => import("../Tutorials/Tutorials"));
const BannerIndex = lazy(() => import('../Banners/BannerIndex'));
const DealIndex = lazy(() => import('../DealRegistration/DealIndex'));
const FirstVisit = lazy(() => import("../FirstVisit"));
const PlayList = lazy(() => import("../PlayList/PlayList"));
const PublicProfile = lazy(() => import("../Discover/PublicProfile"));
const Users = lazy(() => import("../Users/Users"));
const PlayListEdit = lazy(() => import("../PlayList/PlayListEdit"));
const SmackTheFly = lazy(() => import("../WorkShop/SmackTheFly/SmackTheFly"));
const QuizNinja = lazy(() => import("../WorkShop/QuizNinja/QuizNinja"));
const DjLab = lazy(() => import("../WorkShop/DjLab/DjLab"));
const PictureBook = lazy(() => import("../WorkShop/PictureBook/PictureBook"));
const TvLounge = lazy(() => import("../WorkShop/TvLounge/TvLounge"));
const WarOfStrategy = lazy(() => import("../WorkShop/WarOfStrategy/WarOfStrategy"));
const DangerInTheJungle = lazy(() => import("../WorkShop/DangerInTheJungle/DangerInTheJungle"));
const Combination = lazy(() => import("../WorkShop/Combination/Combination"));
const Memory = lazy(() => import("../WorkShop/Memory/Memory"));
const SimpleGameEdit = lazy(() => import("../WorkShop/SimpleGameEdit"));
const BrickOutCreate = lazy(() => import('../WorkShop/BrickOut/BrickOutCreate'));
const BuzzIt = lazy(() => import("../WorkShop/BuzzIt/BuzzIt"));
const BikeRace = lazy(() => import("../WorkShop/BikeRace/BikeRace"));
const SpinTheBottle = lazy(() => import("../WorkShop/SpinTheBottle/SpinTheBottle"));
const Sakura = lazy(() => import("../WorkShop/Sakura/Sakura"));
const UnitsIndex = lazy(() => import("../Units/UnitsIndex"));
const OrganizationProfile = lazy(() => import("../Discover/OrganizationProfile"));
const AdminPage = lazy(() => import("../Admin/AdminPage"));
const JigsawPuzzleView = lazy(() => import("../WorkShop/JigsawPuzzle/JigsawPuzzleView"));
const AddPlaylistSuccess = lazy(() => import('../landingPages/AddPlaylistSuccess'));
const SuperSorterView = lazy(() => import('../WorkShop/SuperSorter/SuperSorterView'));
const WordWizard = lazy(() => import('../WorkShop/WordWizard/WordWizard'));
const DemoMenuIndex = lazy(() => import('../DemoMenu/DemoMenuIndex'));
const DemoMenuEdit = lazy(() => import('../DemoMenu/DemoMenuEdit'));
const LicensesIndex = lazy(() => import('../License/LicenseIndex'));
const FloorIndex = lazy(() => import('../Floor/FloorIndex'));
const Geekster = lazy(() => import('../WorkShop/Geekster/GeeksterView'));
const Ordering = lazy(() => import('../WorkShop/Ordering/Ordering'));
const PainterView = lazy(() => import('../WorkShop/Painter/PainterView'));
const PresentationGameView = lazy(() => import('../WorkShop/Presentation/PresentationView'));
const MyGames = lazy(() => import('../WorkShop/MyGames'));
const OrganizationIndex = lazy(() => import('../Organization/OrganizationIndex'));
const Library = lazy(() => import('../Library/Library'));
const Changelog = lazy(() => import("./Changelog"));
const PopIt = lazy(() => import('../WorkShop/PopIt/PopIt'));
const ElasticAdmin = lazy(() => import('../Admin/ElasticAdmin'));
const ResellerDashboard = lazy(() => import('../Reseller/ResellerDashboard'));
const StorageUnitIndex = lazy(() => import('../License/Storage/StorageUnitIndex'));

interface SecureContextProps{
    me: AccountType|undefined;
    isRtl: boolean;
    myOrg: OrgType|undefined;
    floorContent: FloorContent|undefined;
    setMe: (a: AccountType|undefined) => void;
    setFloorContent: (content: FloorContent|undefined) => void;
    setMyOrg: Dispatch<SetStateAction<OrgType|undefined>>; 
}

interface ActiveContextProps{
    activeOrg: OrgType|undefined;
    setActiveOrg: Dispatch<SetStateAction<OrgType|undefined>>;
}

type MessageAnim = 'spawn' | 'in' | 'out';
export interface Message{
    message: string;
    placeholderData?: {[key: string]: string};
    title: string;
    id: string;
    state: MessageAnim;
}

export interface GlobalModalsActions{
    showGameToFloor: Dispatch<SetStateAction<BaseGame|undefined>>;
    showGameToPlaylist: Dispatch<SetStateAction<BaseGame|undefined>>;
    showPlaylistToFloor: Dispatch<SetStateAction<PlayListType|undefined>>;
    showPlaylistToFloorBulk: Dispatch<SetStateAction<PlayListType|undefined>>;
    showPlaylistToPlaylist: Dispatch<SetStateAction<PlayListType|undefined>>;
    showAccountEditForm: Dispatch<SetStateAction<AccountType|undefined>>;
    showTrackingOptions: Dispatch<SetStateAction<boolean>>
    setLayoutButtons: Dispatch<SetStateAction<ReactNode>>;
    popMsg: (title: string, msg: string, data?: {[key: string]: string}) => void;
    popErrorMsg: (e: FetchError) => void;
    addCurtain: (n: ReactNode) => void;
}

export const emptyFloorContent: FloorContent = {apiKey: "", floorName: "", gameContent: {}, playlistContent: {}};

export const GlobalModalContext = createContext<GlobalModalsActions>({
    showGameToFloor: () => {},
    showPlaylistToFloor: () => {},
    showPlaylistToFloorBulk: () => {},
    showGameToPlaylist: () => {}, 
    showPlaylistToPlaylist: () => {},
    showTrackingOptions: () => {}, 
    setLayoutButtons: () => {}, 
    showAccountEditForm: () => {},
    popMsg: () => { },
    popErrorMsg: () => { },
    addCurtain: () => { },
});
export const SecureContext = createContext<SecureContextProps>({
    me: undefined, 
    myOrg: undefined,
    setMyOrg: () => {}, 
    setMe: () => {},
    floorContent: undefined, 
    setFloorContent: () => {},
    isRtl: false
});

export const ActiveContext = createContext<ActiveContextProps>({
    activeOrg: undefined,
    setActiveOrg: () => {}
})

const addMessage = (setMessages: Dispatch<SetStateAction<Message[]>>, title: string, msg: string, data?: {[key: string]: string}) => {
    const id = Math.random().toString();
    msg = trimChar(msg, "\"");
    setMessages(m => ([...m, {title: title, message: msg, placeholderData: data, id: id, state: 'spawn'}]));
    setTimeout(() => {
        setMessages(m => updateArrayItemsById(m, {title: title, message: msg, placeholderData: data, id: id, state: 'in'}));
        setTimeout(() => {
            setMessages(m => updateArrayItemsById(m, {title: title, message: msg, placeholderData: data, id: id, state: 'out'}));
            setTimeout(() => {
                setMessages(m => m.filter(x => x.id !== id));
            }, 1000); //despawn time
        }, 5000)//in-time
    }, 100)//spawn
}

const errorMsg = (e: FetchError) => {
    if(!e.isJson && e.rawBody) return e.rawBody;
    switch(e.status){
        case 400:
            return (e.body && DictGetValues(e.body).flatMap(x => x)[0]) || "bad_request_error";
        case 401:
            return getToken() ? "unauthorized_error" : "login_expired";
        case 404:
            return "notfound_error";
        case 500:
            return "server_error";
    }
    return "unknown_error";
}

const MyFloorApp = () => {
    const [showChangelog, setShowChangelog] = useState(false);
    const [layoutButtons, setLayoutButtons] = useState<ReactNode>();
    const [addGameToFloor, showGameToFloor] = useState<BaseGame>();
    const [addGameToPlaylist, showGameToPlaylist] = useState<BaseGame>();
    const [addPlaylistToFloor, showPlaylistToFloor] = useState<PlayListType>();
    const [addPlaylistToFloorBulk, showPlaylistToFloorBulk] = useState<PlayListType>();
    const [addPlaylistToPlaylist, showPlaylistToPlaylist] = useState<PlayListType>();
    const [trackingOptionsVisble, showTrackingOptionsVisible] = useState<boolean>(false);
    const [accountEditForm, showAccountEditForm] = useState<AccountType>();
    const [messages, setMessages] = useState<Message[]>([]);
    const [curtains, setCurtains] = useState<Curtain[]>([]);
    const popMsg = (title: string, msg: string, data?: {[key: string]: string}) => addMessage(setMessages, title, msg, data);
    const popErrorMsg = (e: FetchError) => addMessage(setMessages, "pop_msg_error", errorMsg(e));
    const onDismissCurtain = (id: string) => setCurtains(old => old.filter(x => x.id !== id));
    const addCurtain = (node: ReactNode) => setCurtains(old => {
        const id = Math.random().toString();
        return [...old, {node, id, onDismiss: () => onDismissCurtain(id)}]
    });

    const [myOrg, setMyOrg] = useState<OrgType>();
    const activeOrgState = useState<OrgType>();
    const [me, _setMe] = useState<AccountType>();
    const [floorContent, setFloorContent] = useState<FloorContent>();
    
    const setMe = useCallback((a: AccountType|undefined) => {
        if(a){
            localStorage.setItem("profileImage", a.profileImage?.imagePath);
            localStorage.setItem("myfloor_lang", a.language);
            if(a.language){
                moment.locale(a.language === "iw" ? 'he' : a.language);
            }
        }
        _setMe(a);
    }, []);

    const displayGlobalModal: GlobalModalsActions = {
        showGameToFloor, showPlaylistToFloorBulk, showPlaylistToFloor, showGameToPlaylist, 
        showPlaylistToPlaylist, setLayoutButtons, showAccountEditForm, popMsg, popErrorMsg,
        showTrackingOptions: showTrackingOptionsVisible, addCurtain
    }

    EventEffects.usePlaylistUpdatedEffect(p => {
        showPlaylistToPlaylist(pOld => playlistUpdatedSetPlaylist(pOld, p));
        showPlaylistToFloorBulk(pOld => playlistUpdatedSetPlaylist(pOld, p));
        showPlaylistToFloor(pOld => playlistUpdatedSetPlaylist(pOld, p));
    }, []);

    return(
        <GlobalModalContext.Provider value={displayGlobalModal}>
            <SecureContext.Provider value={{me, floorContent, setMe, setFloorContent, myOrg, setMyOrg, isRtl: isRtlActive(me?.language)}} >
                <ActiveContext.Provider value={{activeOrg: activeOrgState[0], setActiveOrg: activeOrgState[1]}}>
                    <BrowserRouter>
                        <Layout
                            buttons={layoutButtons}
                            navMenu={<NavMenu />}
                            globalModals={
                                <>
                                    {addGameToFloor && <GameAddToFloor game={addGameToFloor} onClose={() => showGameToFloor(undefined)} />}
                                    {addPlaylistToFloor && <AddPlaylistToFloor playlist={addPlaylistToFloor} onClose={() => showPlaylistToFloor(undefined)} />}
                                    {addGameToPlaylist && <AddGameToPlaylist game={addGameToPlaylist} onClose={() => showGameToPlaylist(undefined)} /> }
                                    {addPlaylistToFloorBulk && <AddPlaylistBulk playlist={addPlaylistToFloorBulk} onClose={() => showPlaylistToFloorBulk(undefined)} />}
                                    {addPlaylistToPlaylist && <PlaylistAddPlaylist playlist={addPlaylistToPlaylist} onClose={() => showPlaylistToPlaylist(undefined)} />}
                                    {accountEditForm && <AccountEditForm/>}
                                    {trackingOptionsVisble && <TrackingOptions />}
                                </>
                            }
                        > 
                            <Suspense fallback={<Loading visible />}> 
                                <Routes>
                                    <Route path='/login' element={<Login />} />
                                    <Route path='/logout' element={<Logout />} />
                                    <Route path='/passwordreset' element={<ResetPassword />} /> 

                                    <Route path='/landing/addPlaylistSuccess/:pId/account/:aId' element={<AddPlaylistSuccess />} />
                                    <Route path='/landing/addPlaylistError' element={<AddPlaylistSuccess error />} />
                                    <Route path='/landing/addPlaylistAlready/:pId/account/:aId' element={<AddPlaylistSuccess  allreadyAdded />} />
                                    <Route path='/tutorials' element={<Tutorials />} />

                                    <Route element={<SecureRoute />}>
                                        <Route path='/' element={<Discover/>} />
                                        <Route path='/firstvisit' element={<FirstVisit />} />
                                        <Route path='/organization' element={<OrganizationIndex />} />
                                        <Route path='/playlist/*' element={<PlayList />}  />
                                        <Route path='/library' element={<Library />}  />
                                        <Route path='/library/profile/:id' element={<PublicProfile />} />
                                        <Route path='/floor' element={<FloorIndex />}/>
                                        <Route path='/license' element={<LicensesIndex />} />
                                        <Route path='/users' element={<Users />} />
                                        <Route path='/workshop/playlist/edit/:id' element={<PlayListEdit />}/>
                                        <Route path='/units' element={<UnitsIndex />}  />
                                        <Route path='/discover/org/:id' element={<OrganizationProfile />}  />
                                        <Route path='/admin' element={<AdminPage />}  />
                                        <Route path='/storage' element={<StorageUnitIndex />} />
                                        <Route path='/elasticAdmin' element={<ElasticAdmin />} />
                                        <Route path='/banner/:id?' element={<BannerIndex />}  />
                                        <Route path='/deal/:id?' element={<DealIndex />}  />
                                        <Route path='/mygames' element={<MyGames />} />
                                        <Route path='/ad' element={<AdIndex />} />
                                        <Route path='/stats' element={<OrganizationCharts />} />
                                        <Route path='/reseller' element={<ResellerDashboard />}/>
                                        <Route path='/reseller/license' element={<LicensesIndex />} />
                                        <Route path='/reseller/banner/:id?' element={<BannerIndex />}  />
                                        <Route path='/reseller/deal/:id?' element={<DealIndex />}  />
                                        <Route path='/reseller/units' element={<UnitsIndex />}  />
                                        <Route path='/reseller/stats' element={<OrganizationCharts />} />
                                        <Route path='/reseller/storage' element={<StorageUnitIndex />} />

                                        <Route path='/workshop'>
                                            {SimpleGameContexts.map(x => 
                                                <Route
                                                    key={x.gameType} 
                                                    path={`${x.gameType}/edit/:id`} 
                                                    element={<SimpleGameEdit context={x} />}  
                                                />
                                            )}
                                            <Route path='brickout/create' element={<BrickOutCreate />}  />
                                            <Route path='createstaticgame' element = {<CreateStaticGame/>}/>

                                            <Route path='smackthefly/create' element={<SmackTheFly />} />
                                            <Route path='smackthefly/edit/:id?' element={<SmackTheFly />} />

                                            <Route path='painter/create' element={<PainterView />} />
                                            <Route path='painter/edit/:id?' element={<PainterView />} />

                                            <Route path='geekster/create' element={<Geekster />} />
                                            <Route path='geekster/edit/:id?' element={<Geekster />} />

                                            <Route path='quizninja/create' element={<QuizNinja />}  />
                                            <Route path='quizninja/edit/:id?' element={<QuizNinja />}  />

                                            <Route path='djlab/create' element={<DjLab />}  />
                                            <Route path='djlab/edit/:id?' element={<DjLab />}  />

                                            <Route path='picturebook/create' element={<PictureBook />}  />
                                            <Route path='picturebook/edit/:id?' element={<PictureBook />}  />

                                            <Route path='tvlounge/create' element={<TvLounge />}  />
                                            <Route path='tvlounge/edit/:id' element={<TvLounge />}  />

                                            <Route path='warofstrategy/create' element={<WarOfStrategy />}  />
                                            <Route path='warofstrategy/edit/:id' element={<WarOfStrategy />}  />

                                            <Route path='dangerinthejungle/create' element={<DangerInTheJungle />}  />
                                            <Route path='dangerinthejungle/edit/:id' element={<DangerInTheJungle />}  />

                                            <Route path='combination/create' element={<Combination />}  />
                                            <Route path='combination/edit/:id' element={<Combination />}  />

                                            <Route path='presentation/create' element={<PresentationGameView />}  />
                                            <Route path='presentation/edit/:id' element={<PresentationGameView />}  />

                                            <Route path='memory/create' element={<Memory />}  />
                                            <Route path='memory/edit/:id' element={<Memory />}  />

                                            <Route path='buzzit/create' element={<BuzzIt />} />
                                            <Route path='buzzit/edit/:id' element={<BuzzIt />} />

                                            <Route path='bikerace/create' element={<BikeRace />} />
                                            <Route path='bikerace/edit/:id' element={<BikeRace />} />

                                            <Route path='spinthebottle/create' element={<SpinTheBottle />} />
                                            <Route path='spinthebottle/edit/:id' element={<SpinTheBottle />} />

                                            <Route path='sakura/create' element={<Sakura />} />
                                            <Route path='sakura/edit/:id' element={<Sakura />} />

                                            <Route path='jigsawpuzzle/create' element={<JigsawPuzzleView />} />
                                            <Route path='jigsawpuzzle/edit/:id' element={<JigsawPuzzleView />} />

                                            <Route path='supersorter/create' element={<SuperSorterView />}  />
                                            <Route path='supersorter/edit/:id' element={<SuperSorterView />}  />

                                            <Route path='wordwizard/create' element={<WordWizard />}  />
                                            <Route path='wordwizard/edit/:id' element={<WordWizard />}  />

                                            <Route path='ordering/create' element={<Ordering />}  />
                                            <Route path='ordering/edit/:id' element={<Ordering />}  />

                                            <Route path='presentationgame/edit/:id' element={<DemoMenuEdit />}  />
                                            <Route path='presentationgame/create' element={<DemoMenuIndex />}  />

                                            <Route path='popit/create' element={<PopIt />}  />
                                            <Route path='popit/edit/:id' element={<PopIt />}  />

                                            <Route path='wordfinder/create' element={<WordFinder />}  />
                                            <Route path='wordfinder/edit/:id' element={<WordFinder />}  />
                                        </Route>
                                    </Route>
                                    <Route path='*' element={<NotFound />}/>
                                </Routes>
                            </Suspense>
                            <PopContainer messages={messages} />
                            <CurtainsContainer curtains={curtains} />
                            <div className='version-pixel' onClick={() => setShowChangelog(true)}>v.</div>
                            {showChangelog && <Suspense fallback={<Loading visible />} ><Changelog onClose={() => setShowChangelog(false)}/></Suspense>}
                        </Layout>
                    </BrowserRouter>
                </ActiveContext.Provider>
            </SecureContext.Provider>
        </GlobalModalContext.Provider>
    )
}

export default MyFloorApp;